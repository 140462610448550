import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const PreviewCompatibleImage = ({ imageInfo }) => {
  const { alt = '', title = '', image } = imageInfo;

  const imgChild = getImage(image);
  if (!!image && !!image.childImageSharp) {
    return (
      <GatsbyImage
        image={imgChild}
        alt={title || alt}
        object-fit="cover"
        width="100%"
        height="100%"
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    );
  }
  return null;
};

export default PreviewCompatibleImage;
