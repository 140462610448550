import React, { useEffect, useRef } from 'react';
import gsap, { Power1 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { useTranslation } from 'react-i18next';

import * as S from './styled';
import TitlePage from '../TitlePage';

import Carousel from '../../utils/carousel';

const HomeWebsiteFeatures = ({ features }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  gsap.registerPlugin(ScrollTrigger);

  let container = useRef(null);
  let introContent = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({
      paused: true,
      defaults: { duration: 2.5, ease: Power1.easeInOut },
      scrollTrigger: {
        trigger: container,
        start: '+=200 bottom-=100px',
        end: '+=500',
        // markers: true,
      },
    });
    gsap.set(container, { alpha: 0, y: -100 });
    tl.to(container, { alpha: 1, y: 0 });
  }, []);

  useEffect(() => {
    const tl = gsap.timeline({
      paused: true,
      defaults: { duration: 0.5, ease: Power1.easeInOut },
      scrollTrigger: {
        trigger: introContent,
        start: '+=200 bottom-=100px',
        end: '+=500',
        // markers: true,
      },
    });
    gsap.set(introContent, { alpha: 0, y: -100 });
    tl.to(introContent, { alpha: 1, y: 0 });
  }, []);

  useEffect(() => {
    Carousel('.carousel-features', '.carousel-feature');
  });
  return (
    <S.Wrapper id="features">
      <S.Container>
        <TitlePage
          text={t(`${features.title}`)}
          subtitle={t(`${features.subtitle}`)}
          contactSubtitle
        />
        <S.ContainerContent>
          <S.Content
            ref={(el) => {
              introContent = el;
            }}
          >
            {features.exerpt}
          </S.Content>
        </S.ContainerContent>
        <S.ServicesWrapper
          ref={(el) => {
            container = el;
          }}
        >
          <div className="carousel-features">
            {features &&
              features.items.map((service) => {
                const img = getImage(service.image);
                return (
                  <div
                    className="carousel-feature"
                    key={service.title}
                  >
                    <Link to="/strony-internetowe">
                      <S.ImageContainer>
                        <GatsbyImage
                          image={img}
                          alt={service.title}
                          object-fit="contain"
                        />
                      </S.ImageContainer>
                    </Link>
                    <S.Title>{service.title}</S.Title>
                  </div>
                );
              })}
          </div>
        </S.ServicesWrapper>
      </S.Container>
    </S.Wrapper>
  );
};

export default HomeWebsiteFeatures;
