import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { useTranslation } from 'react-i18next';
import { ScrollTrigger } from 'gsap/all';
import { Clock } from '@styled-icons/fa-solid';

import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';
import Anchorlink from '../../components/Anchorlink';
import TitlePage from '../../components/TitlePage';

import * as S from './styled';

const BlogRoll = ({ posts }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });

  const cards = useRef([]);

  cards.current = [];

  const addToRefs = (el) => {
    if (el && !cards.current.includes(el)) {
      cards.current.push(el);
    }
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set(cards.current, { autoAlpha: 0, scale: 0.9 });
    ScrollTrigger.batch(cards.current, {
      start: 'top bottom-=100px',
      onEnter: (batch) =>
        gsap.to(batch, {
          duration: 0.8,
          autoAlpha: 1,
          scale: 1,
          ease: 'none',
          stagger: 0.3,
        }),
    });
  }, []);
  return (
    <S.Wrapper id="blog">
      <S.Container>
        <TitlePage
          text={t('latestPosts')}
          subtitle={t('blogSubtitle')}
        />
        <S.ArticlesWrapper>
          {posts &&
            posts.map((post) => (
              <S.Article ref={addToRefs} key={post.id}>
                {post.frontmatter.featuredimage ? (
                  <S.ImageContainer>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: post.frontmatter.featuredimage.src,
                        alt: `${post.frontmatter.featuredimage.alt}`,
                      }}
                    />
                  </S.ImageContainer>
                ) : null}
                <S.ArticlePreview>
                  <Anchorlink to={post.fields.slug} ariaLabel="title">
                    <S.ArticleTitle>
                      {post.frontmatter.title}
                    </S.ArticleTitle>
                  </Anchorlink>

                  <S.FeatureParagraph>
                    <S.Exerpt>{post.excerpt}</S.Exerpt>
                    <br />
                    <Anchorlink
                      to={post.fields.slug}
                      ariaLabel="czytaj więcej"
                    >
                      {t('toRead')} →
                    </Anchorlink>
                    <S.MetaTags>
                      <S.Date>
                        <Clock size={18} />
                      </S.Date>
                      <S.Date>{post.frontmatter.date}</S.Date>
                    </S.MetaTags>
                  </S.FeatureParagraph>
                </S.ArticlePreview>
              </S.Article>
            ))}
        </S.ArticlesWrapper>
      </S.Container>
    </S.Wrapper>
  );
};

export default BlogRoll;
