import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { useTranslation } from 'react-i18next';

import PreviewCompatibleImage from '../PreviewCompatibleImage';
import Anchorlink from '../Anchorlink';

import * as S from './styled';

const WebsiteProjectBox = ({ image, alt, slug, title, subtitle }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  let cardProject = useRef(null);
  let titleRef = useRef(null);
  let subtitleRef = useRef(null);
  let button = useRef(null);

  const tl = gsap.timeline({
    paused: true,
    defaults: { duration: 0.4, ease: 'power1' },
  });
  useEffect(() => {
    tl.to(cardProject, {
      y: 0,
    })
      .to(titleRef, {
        y: 0,
      })
      .to(subtitleRef, {
        y: 0,
        opacity: 1,
      })
      .to(button, {
        y: 0,
        opacity: 1,
      });
  }, []);

  const handleMouseOver = () => {
    tl.play();
  };

  const handleMouseOut = () => {
    tl.reverse();
  };

  return (
    <S.Container
      onMouseLeave={handleMouseOut}
      onMouseOver={handleMouseOver}
    >
      <S.ImageContainer>
        <PreviewCompatibleImage
          imageInfo={{
            image,
            alt,
          }}
        />
      </S.ImageContainer>

      <S.ProjectContainer
        ref={(el) => {
          cardProject = el;
        }}
      >
        <S.ProjectTitle
          ref={(el) => {
            titleRef = el;
          }}
        >
          {title}
        </S.ProjectTitle>
        <S.ProjectSubTitle
          ref={(el) => {
            subtitleRef = el;
          }}
        >
          {subtitle}
        </S.ProjectSubTitle>
        <Anchorlink to={`${slug}`} ariaLabel="title">
          <S.Button
            ref={(el) => {
              button = el;
            }}
          >
            {t('toRead')}
          </S.Button>
        </Anchorlink>
      </S.ProjectContainer>
    </S.Container>
  );
};

export default WebsiteProjectBox;
