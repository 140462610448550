import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.section`
  display: flex;
  position: relative;
  z-index: 4;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  ${media.greaterThan('medium')`
     padding: 10px 25px;
  `}
  ${media.greaterThan('large')`
    margin: 0 auto;
  `}
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  ${media.greaterThan('large')`
    max-width:1170px;
  `}
`;

export const ArticlesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(200px, 1fr));
  grid-gap: 20px;
  position: relative;
  width: 100%;
  justify-items: center;
  ${media.greaterThan('medium')`
    padding:40px 0 0;
  `}
  ${media.greaterThan('large')`
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
  `}
`;

export const Article = styled.article`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  visibility: hidden;
  ${media.greaterThan('medium')`
    margin: 0 auto;
    flex: 0 0 calc(100% / 3 - 20px);
    width: 400px;
  `}
`;

export const ImageContainer = styled.div`
  height: 300px;
  overflow: hidden;
`;

export const ArticlePreview = styled.div`
  padding: 15px 5px;
`;

export const MetaTags = styled.div`
  margin-bottom: 10px;
`;

export const Date = styled.span`
  color: var(--primary-color);
  margin-right: 10px;
`;

export const ArticleTitle = styled.h2`
  line-height: 26px;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--thirdy-color);
  ${media.greaterThan('medium')`
    min-height: 52px;
    `}
`;

export const FeatureParagraph = styled.div`
  font-size: 14px;
  font-family: var(--font-open);
  color: var(--primary-color);
`;

export const Exerpt = styled.p`
  margin: 0;
  padding: 0;
  ${media.greaterThan('medium')`
    min-height: 106px;
    `}
`;
