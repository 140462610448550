import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.section`
  display: flex;
  position: relative;
  min-height: 50vh;
  z-index: 4;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  ${media.greaterThan('medium')`
     padding: 10px 25px;
  `}
  ${media.greaterThan('large')`
    margin: 0 auto;
  `}
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  ${media.greaterThan('large')`
    max-width:1170px;
  `}
`;

export const ServicesWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  .carousel-boxes {
    display: flex;
    width: 150%;
  }
  .carousel-box {
    flex-direction: column;
    width: 250px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    text-align: center;
    color: var(--gray-light);
    margin: 0 20px 0 0;
  }
  ${media.greaterThan('medium')`
    padding:40px 0 0;
  `}
  ${media.greaterThan('large')`
  grid-template-columns: repeat(2, minmax(200px, 1fr));
    margin: 0 auto;
  `}
`;

export const ImageContainer = styled.div`
  padding: 10px;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h2`
  width: 100%;
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
  margin: 10px 0;
  color: var(--primary-color);
  text-align: center;
`;
