import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';

import square from '../../assets/images/square.svg';
import arrows from '../../assets/images/arrows.svg';
import * as S from './styled';

const ButtonSliderNav = ({ position, onClick }) => {
  let arrowsRef = useRef(null);
  useEffect(() => {
    gsap.to(arrowsRef, {
      duration: 1,
      repeat: -1,
      yoyo: true,
      x: '6px',
    });
    return () => {
      gsap.killTweensOf(arrowsRef);
    };
  }, []);
  return (
    <S.ScrollWrapper
      onClick={onClick}
      position={position}
      aria-label="slider navigation"
      data-position={position}
      type="button"
      className="nextButton"
    >
      <S.RectContainer>
        <img src={square} alt="Rect" />
      </S.RectContainer>
      <S.ArrowsContainer
        ref={(el) => {
          arrowsRef = el;
        }}
        position={position}
      >
        <img src={arrows} alt="Arrows" />
      </S.ArrowsContainer>
    </S.ScrollWrapper>
  );
};

ButtonSliderNav.propTypes = {
  position: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ButtonSliderNav;
