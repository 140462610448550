import styled from 'styled-components';
// import media from 'styled-media-query'

export const ScrollWrapper = styled.button`
  background: transparent;
  display: inline-block;
  width: 45px;
  height: 150px;
  outline: none;
  position: relative;
  z-index: 4;
  border: none;
  transition: transform 0.5s ease;
  transform: translateY(7rem);
  opacity: 0;
  &:hover {
    div {
      transform: scale(1.1);
      transition: transform 0.5s ease;
    }
  }
`;

export const RectContainer = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.7s ease;
  overflow-y: hidden;
`;
export const ArrowsContainer = styled.div`
  display: inline-block;
  position: absolute;
  top: 100px;
  left: 13px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thirdy-color);
  font-family: 'Anton', sans-serif;
  text-transform: uppercase;
  z-index: 5;
`;
