import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  position: relative;
  height: 100vh;
  /* z-index: 0; */
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: var(--body-bg); ;
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  /* z-index: 2; */
`;

export const Radial = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 2;
  background: radial-gradient(
    85.2% 66.21% at 53.75% 48.24%,
    rgba(57, 63, 77, 0.1) 38.7%,
    #393f4d 90.07%
  );
`;
export const LightBox = styled.div`
  position: fixed;
  width: 50%;
  height: 100vh;
  left: 0px;
  top: 0px;
  z-index: 3;
  background: var(--secondary-color);
  mix-blend-mode: lighten;
  opacity: 0.8;
`;

export const ScrollContainer = styled.div`
  position: absolute;
  bottom: 10px;
`;
