import React from 'react';
import * as S from './styled';

const HeroTitle = ({ text, subtitle }) => (
  <S.Container>
    <S.Title>
      <S.TitleElement className="hero-element hero-title">
        {text}
      </S.TitleElement>
    </S.Title>
    <S.Description className="hero-element hero-description">
      {subtitle}
    </S.Description>
  </S.Container>
);
export default HeroTitle;
