import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.section`
  display: flex;
  position: relative;
  /* min-height: 100vh; */
  z-index: 4;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  ${media.greaterThan('medium')`
     padding: 10px 25px;
  `}
  ${media.greaterThan('large')`
    margin: 0 auto;
  `}
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  ${media.greaterThan('large')`
    max-width:1170px;
  `}
`;

export const ProjectsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(200px, 1fr));
  grid-gap: 20px;
  position: relative;
  width: 100%;
  padding: 20px 0 0;
  ${media.greaterThan('medium')`
    justify-items: center;
    padding:40px 0 0;
  `}
  ${media.greaterThan('large')`
  grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
  `}
`;
