import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'gatsby-theme-i18n';
import { navigate } from 'gatsby';
import gsap, { Power1 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import * as S from './styled';

function encode(data) {
  return Object.keys(data)
    .map((key) => {
      const enKey = encodeURIComponent(key);
      const enData = encodeURIComponent(data[key]);
      return `${enKey}=${enData}`;
    })
    .join('&');
}

const ContactForm = () => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  gsap.registerPlugin(ScrollTrigger);
  const { locale } = useLocalization();

  const defaultValues = {
    name: '',
    phone: '',
    email: '',
    message: '',
  };
  const [isValidated, setIsValidated] = useState(defaultValues);
  const handleChange = (e) => {
    setIsValidated({
      ...isValidated,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...isValidated,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  const formItems = useRef([]);

  formItems.current = [];

  const addToRefs = (el) => {
    if (el && !formItems.current.includes(el)) {
      formItems.current.push(el);
    }
  };

  useEffect(() => {
    // gsap.set(formItems.current, { autoAlpha: 0, scale: 0.9 });
    ScrollTrigger.batch(formItems.current, {
      start: 'top 100%',
      onEnter: (batch) =>
        gsap.from(batch, {
          duration: 1,
          opacity: 0,
          y: -50,
          stagger: 0.2,
          ease: Power1.easeOut,
          delay: 0.5,
        }),
      onLeaveBack: (batch) =>
        gsap.to(batch, {
          duration: 1,
          opacity: 1,
          y: 0,
          stagger: 0.1,
          ease: Power1.easeOut,
          delay: 0.5,
        }),
    });
  }, []);

  return (
    <>
      <S.Title ref={addToRefs}>{t('form')}</S.Title>
      <form
        name="contact-portfolio-greg"
        method="post"
        action={locale === 'en' ? '/en/thanks/' : '/thanks/'}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label htmlFor="bot-field">
            {t('doNotFill')}
            <input
              name="bot-field"
              id="bot-field"
              onChange={handleChange}
            />
          </label>
        </div>
        <S.Field ref={addToRefs}>
          <S.FormInput
            type="text"
            name="name"
            onChange={handleChange}
            id="name"
            value={isValidated.name}
            required
          />
          <S.Highlight />
          <S.Bar />
          <S.ErrorMessage>{t('errorName')}</S.ErrorMessage>
          <S.Label htmlFor="name">{t('nameAndSurname')}</S.Label>
        </S.Field>
        <S.Field ref={addToRefs}>
          <S.FormInput
            type="email"
            name="email"
            onChange={handleChange}
            id="email"
            value={isValidated.email}
            required
          />
          <S.Highlight />
          <S.Bar />
          <S.ErrorMessage>{t('errorEmail')}</S.ErrorMessage>
          <S.Label htmlFor="email">{t('email')}</S.Label>
        </S.Field>
        <S.Field ref={addToRefs}>
          <S.FormInput
            type="phone"
            name="phone"
            onChange={handleChange}
            id="phone"
            value={isValidated.phone}
            pattern="[0-9]{9,12}"
            required
          />

          <S.Highlight />
          <S.Bar />
          <S.ErrorMessage>{t('errorPhone')}</S.ErrorMessage>
          <S.Label htmlFor="phone">{t('phone')}</S.Label>
        </S.Field>
        <S.Field ref={addToRefs}>
          <S.FormTextArea
            as="textarea"
            rows="8"
            name="message"
            onChange={handleChange}
            id="message"
            value={isValidated.message}
            required
          />
          <S.Highlight />
          <S.Bar />
          <S.ErrorMessage>{t('errorMessage')}</S.ErrorMessage>
          <S.Label htmlFor="message">{t('message')}</S.Label>
        </S.Field>
        <S.FieldButton ref={addToRefs}>
          <S.FormButton type="submit">{t('send')}</S.FormButton>
        </S.FieldButton>
      </form>
    </>
  );
};

export default ContactForm;
