import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query';

const inputHighlighter = keyframes`
from { 
  background:var(--gray); 
  }
to 	{ 
  width:0; 
  background:transparent; 
  }
`;

export const Title = styled.h2`
  line-height: 100%;
  font-size: 2.2rem;
  font-family: var(--font-open);
  color: var(--primary-color);
  margin: 20px 0;
  ${media.greaterThan('medium')`
    line-height: 1.1;
    font-size: 2.8rem;
  `}
`;

export const Field = styled.div`
  position: relative;
  margin-bottom: 40px;
`;

export const FieldButton = styled(Field)`
  display: flex;
  justify-content: center;
`;

export const Label = styled.label`
  color: var(--gray);
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  left: 10px;
  top: 10px;
  transition: 0.2s ease all;
`;

export const Highlight = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
`;

export const Bar = styled.span`
  position: relative;
  display: block;
  margin: 0 auto;
  width: calc(100% - 4px);
  ${media.greaterThan('medium')`
    /* width: 500px; */
  `}
  &:before,
  &:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: var(--thirdy-color);
    transition: 0.2s ease all;
  }
  &:before {
    left: 50%;
  }
  &:after {
    right: 50%;
  }
`;

export const ErrorMessage = styled.span`
  margin: 2px 0 0 0;
  padding: 0;
  font-size: 14px;
  color: red;
  opacity: 0;
  position: absolute;
  left: 10px;
  top: 10px;
  transition: 0.2s ease all;
  visibility: hidden;
`;

export const FormInput = styled.input`
  font-size: 16px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 310px;
  border: 2px solid var(--gray);
  color: var(--primary-color);
  background-color: transparent;
  ${media.greaterThan('medium')`
    width: 370px;
  `}
  &:focus {
    outline: none;
  }
  &:not([value='']) {
    &:not(textarea) {
      &:invalid ~ ${Label} {
        top: -22px;
        font-size: 14px;
        color: red;
      }
      &:invalid ~ ${ErrorMessage} {
        top: 40px;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &:focus ~ ${Label}, &:valid ~ ${Label} {
    top: -22px;
    font-size: 14px;
    color: var(--thirdy-color);
  }
  &:focus ~ ${Bar}:before, &:focus ~ ${Bar}:after {
    width: 50%;
  }
  &:focus ~ ${Highlight} {
    animation: ${inputHighlighter} 0.5s ease;
  }
  &:-webkit-autofill {
    box-shadow: 0 0 0 30px var(--gray-dark) inset;
    -webkit-box-shadow: 0 0 0 30px var(--gray-dark) inset;
    -webkit-text-fill-color: var(--primary-color);
  }
`;

export const FormTextArea = styled(FormInput)`
  resize: vertical;
  max-height: 40em;
  min-height: 8em;
`;

export const FormButton = styled.button`
  font-size: 1.5rem;
  padding: 15px 45px;
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.2s linear, background-color 0.2s linear;
  display: inline-block;
  background: var(--primary-color);
  color: var(--secondary-color);
  border: 1px solid var(--primary-color);
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  &:focus {
    color: var(--primary-color);
    background: var(--secondary-color);
  }
  &:active {
    color: var(--primary-color);
    background: var(--secondary-color);
  }
  &:hover {
    color: var(--primary-color);
    background: var(--secondary-color);
    transition: all 0.2s ease-in-out;
  }
`;
