import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

import rect from '../../assets/images/rect.svg';
import arrows from '../../assets/images/arrows.svg';

const ButtonScroll = () => {
  const { t } = useTranslation();
  let arrowsRef = useRef(null);
  const scrollDown = () => {
    const windowHeight = window.innerHeight;
    window.scrollTo({
      top: windowHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    gsap.to(arrowsRef, {
      duration: 1,
      repeat: -1,
      yoyo: true,
      y: '8px',
    });
    return () => {
      gsap.killTweensOf(arrowsRef);
    };
  }, []);

  return (
    <S.ScrollWrapper
      onClick={scrollDown}
      className="header-element-scroll"
    >
      <S.Label>{t('scroll')}</S.Label>
      <S.RectContainer>
        <img src={rect} alt="Rect" />
      </S.RectContainer>
      <S.ArrowsContainer
        ref={(el) => {
          arrowsRef = el;
        }}
      >
        <img src={arrows} alt="Arrows" />
      </S.ArrowsContainer>
    </S.ScrollWrapper>
  );
};

export default ButtonScroll;
