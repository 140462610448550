import styled from 'styled-components';
import media from 'styled-media-query';

export const TitleContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 5%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  z-index: 8;
  padding: 0 10px;
  ${media.greaterThan('medium')`
    left: 15%;
    padding: 0 20px;
  `}
  ${media.greaterThan('large')`
    left: 20%;
    padding: 0 100px;
  `}
`;
