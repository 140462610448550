import styled from 'styled-components';
import media from 'styled-media-query';

export const ButtonWrapper = styled.button`
  background: transparent;
  display: inline-block;
  width: 300px;
  height: 63px;
  outline: none;
  position: relative;
  z-index: 4;
  border: none;
  opacity: 0;
  transform: translateY(-1000px);
  /* transform: scale(0.9); */
  &:hover {
    transition: all 0.5s ease;
    div {
      color: var(--primary-color);
      transition: all 0.5s ease;
      transform: scale(1.1);
      svg {
        path {
          stroke: var(--primary-color);
          transition: all 0.5s ease;
        }
      }
    }
  }
  ${media.greaterThan('medium')`
    transform: scale(1);
  `}
`;

export const RectContainer = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s ease;
`;

export const Label = styled.div`
  display: inline-block;
  color: var(--thirdy-color);
  font-family: 'Anton', sans-serif;
  font-size: 2.4rem;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px var(--gray-dark);
  z-index: 5;
  transition: all 0.5s ease;
`;
