import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import gsap, { Power1 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {
  GithubSquare as Github,
  Linkedin,
} from '@styled-icons/fa-brands';
import { Mail, Phone } from '@styled-icons/material-rounded';

import * as S from './styled';

function ContactSocialMedia() {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  gsap.registerPlugin(ScrollTrigger);
  const socialItems = useRef([]);
  socialItems.current = [];

  const addToRefs = (el) => {
    if (el && !socialItems.current.includes(el)) {
      socialItems.current.push(el);
    }
  };

  useEffect(() => {
    // gsap.set(formItems.current, { autoAlpha: 0, scale: 0.9 });
    ScrollTrigger.batch(socialItems.current, {
      start: 'top 100%',
      onEnter: (batch) =>
        gsap.from(batch, {
          duration: 1,
          opacity: 0,
          y: -50,
          stagger: 0.2,
          ease: Power1.easeOut,
          delay: 0.5,
        }),
      onLeaveBack: (batch) =>
        gsap.to(batch, {
          duration: 1,
          opacity: 1,
          y: 0,
          stagger: 0.1,
          ease: Power1.easeOut,
          delay: 0.5,
        }),
    });
  }, []);

  return (
    <>
      <S.Title ref={addToRefs}>{t('socialMedia')}</S.Title>
      <S.SocialMediaContainer>
        <S.ItemContainer ref={addToRefs}>
          <a
            href="https://www.linkedin.com/in/grzegorz-domaradzki"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <S.IconBox color="#0089D2">
              <Linkedin size="60" title="Linkedin Profile" />
            </S.IconBox>
          </a>
        </S.ItemContainer>
        <S.ItemContainer ref={addToRefs}>
          <a
            href="https://github.com/domaradzki"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <S.IconBox color="#0d1117">
              <Github size="60" title="Github account" />
            </S.IconBox>
          </a>
        </S.ItemContainer>
        <S.ItemContainer ref={addToRefs}>
          <a href="mailto:domaradzki.g@gmail.com">
            <S.IconBox color="#393F4D">
              <Mail size="60" title="Mail" />
            </S.IconBox>
          </a>
        </S.ItemContainer>
        <S.ItemContainer ref={addToRefs}>
          <a href="tel:+48604577856">
            <S.IconBox color="#007C00">
              <Phone size="60" title="Phone" />
            </S.IconBox>
          </a>
        </S.ItemContainer>
      </S.SocialMediaContainer>
    </>
  );
}
export default ContactSocialMedia;
