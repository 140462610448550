import React, { useEffect, useRef } from 'react';
import { gsap, Power4 } from 'gsap';
import { useTranslation } from 'react-i18next';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import WebsiteProjectBox from '../WebsiteProjectBox';
import * as S from './styled';
import TitlePage from '../TitlePage';

const WebsiteProjects = ({ projects }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  const cards = useRef([]);

  cards.current = [];

  const addToRefs = (el) => {
    if (el && !cards.current.includes(el)) {
      cards.current.push(el);
    }
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set(cards.current, { autoAlpha: 0, scale: 0.9 });
    ScrollTrigger.batch(cards.current, {
      start: 'top bottom-=100px',
      onEnter: (batch) =>
        gsap.to(batch, {
          duration: 1,
          autoAlpha: 1,
          scale: 1,
          ease: Power4.easeOut,
          stagger: 0.3,
          delay: 0.5,
        }),
    });
  }, []);

  return (
    <S.Wrapper id="projects">
      <S.Container>
        <TitlePage
          text={t('websites')}
          subtitle={t('recentProjects')}
          contactSubtitle
        />
        <S.ProjectsWrapper>
          {projects &&
            projects.map((project) => (
              <div ref={addToRefs} key={project.id}>
                <WebsiteProjectBox
                  image={project.frontmatter.featuredimage.src}
                  alt={project.frontmatter.featuredimage.alt}
                  slug={project.fields.slug}
                  title={project.frontmatter.heading}
                  subtitle={project.frontmatter.subtitle}
                />
              </div>
            ))}
        </S.ProjectsWrapper>
      </S.Container>
    </S.Wrapper>
  );
};

export default WebsiteProjects;
