import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.section`
  display: flex;
  position: relative;
  min-height: 100vh;
  z-index: 4;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    116.6deg,
    #1d1e22 0.33%,
    #1d1e22 47.95%,
    #e9b000 47.96%,
    #e9b000 100%
  );
  padding: 10px 15px;
  ${media.greaterThan('medium')`
     padding: 10px 25px;
  `}
  ${media.greaterThan('large')`
    margin: 0 auto;
  `}
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  ${media.greaterThan('large')`
    max-width:1170px;
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-top: 50px;
  padding: 0 50px;
`;

export const Block = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.greaterThan('1024px')`
    flex: 0 0 50%;
    max-width: 50%;

  `}
`;
