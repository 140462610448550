import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.article`
  position: relative;
  overflow: hidden;
  /* width: 100%; */
  /* height: 100%; */
  ${media.greaterThan('medium')`
      max-width: 400px;
  `}
`;

export const ProjectContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--gray-transparent);
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  transform: translateY(240px);
`;

export const ImageContainer = styled.div`
  height: 300px;
  overflow: hidden;
`;

export const ProjectTitle = styled.h2`
  width: 100%;
  line-height: 40px;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 10px;
  font-family: var(--font-anton);
  color: var(--primary-color);
  text-align: center;
  transform: translateY(-18px);
`;

export const ProjectSubTitle = styled.h3`
  width: 100%;
  line-height: 30px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--primary-color);
  text-align: center;
  word-wrap: break-word;
  transform: translateY(50px);
  opacity: 0;
`;

export const Button = styled.button`
  font-size: 1.5rem;
  padding: 15px 45px;
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.2s linear, background-color 0.2s linear;
  display: inline-block;
  background: var(--thirdy-color);
  color: var(--secondary-color);
  border: 1px solid var(--thirdy-color);
  position: relative;
  z-index: 1;
  transform: translateY(50px);
  opacity: 0;
  &:focus {
    color: var(--thirdy-color);
    background: var(--secondary-color);
  }
  &:active {
    color: var(--thirdy-color);
    background: var(--secondary-color);
  }
  &:hover {
    color: var(--thirdy-color);
    background: var(--secondary-color);
    transition: all 0.2s ease-in-out;
  }
`;
