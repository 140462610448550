import styled from 'styled-components';
import media from 'styled-media-query';

export const Title = styled.h2`
  line-height: 100%;
  font-size: 2.2rem;
  font-family: var(--font-open);
  color: var(--gray-dark);
  margin: 20px 0;
  ${media.greaterThan('medium')`
   line-height: 1.1;
    font-size: 2.8rem;
  `}
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 160px;
  width: 100%;
  height: 100%;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

export const IconBox = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: 15px;
  color: ${({ color }) => color};
  position: relative;
  transition: color 0.2s 0.1s ease-in-out;
  &:hover {
    color: var(--primary-color);
  }
`;
