import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import gsap, { Power1 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import TitlePage from '../TitlePage';

import Carousel from '../../utils/carousel';

const HomeServices = ({ services }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  gsap.registerPlugin(ScrollTrigger);

  let container = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({
      paused: true,
      defaults: { duration: 2.5, ease: Power1.easeInOut },
      scrollTrigger: {
        trigger: container,
        start: '+=200 bottom-=100px',
        end: '+=500',
        // markers: true,
      },
    });
    gsap.set(container, { alpha: 0, y: -100 });
    tl.to(container, { alpha: 1, y: 0 });
  }, []);

  useEffect(() => {
    Carousel('.carousel-boxes', '.carousel-box');
  });
  return (
    <S.Wrapper id="services">
      <S.Container>
        <TitlePage
          text={t(`${services.title}`)}
          subtitle={t(`${services.subtitle}`)}
          contactSubtitle
        />
        <S.ServicesWrapper
          ref={(el) => {
            container = el;
          }}
        >
          <div className="carousel-boxes">
            {services &&
              services.items.map((service) => {
                const img = getImage(service.image);
                return (
                  <div className="carousel-box" key={service.title}>
                    <Link to="/uslugi-informatyczne">
                      <S.ImageContainer>
                        <GatsbyImage
                          image={img}
                          alt={service.title}
                          object-fit="contain"
                        />
                      </S.ImageContainer>
                    </Link>
                    <S.Title>{service.title}</S.Title>
                  </div>
                );
              })}
          </div>
        </S.ServicesWrapper>
      </S.Container>
    </S.Wrapper>
  );
};

export default HomeServices;
