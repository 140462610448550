import styled from 'styled-components';
import media from 'styled-media-query';

export const ScrollWrapper = styled.button`
  display: inline-block;
  position: absolute;
  top: 50%;
  ${({ position }) => position}: 10px;
  background: transparent;
  z-index: 999;
  width: 45px;
  height: 45px;
  outline: none;
  cursor: crosshair;
  border: none;
  -webkit-font-smoothing: antialiased;
  transition: display 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: ${({ position }) =>
    position === 'right' ? 'translateX(7rem)' : 'translateX(-7rem)'};
  opacity: 0;
  &:hover {
    div {
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      transform: scale(1.2);
      opacity: 1;
    }
  }
  ${media.lessThan('small')`
    display:none ;
  `}
`;

export const RectContainer = styled.div`
  display: inline-block;
  position: absolute;
  opacity: 0.6;
  top: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  left: 0;
`;

export const ArrowsContainer = styled.div`
  display: inline-block;
  position: absolute;
  opacity: 0.6;
  top: 10px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  left: 10px;
  img {
    transform: ${({ position }) =>
      position === 'right' ? 'rotate(-90deg)' : 'rotate(90deg)'};
  }
`;
