import React from 'react';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

const ButtonCallAction = () => {
  const { t } = useTranslation();

  return (
    <S.ButtonWrapper className="hero-button">
      <LocalizedLink to="/#contact">
        <S.Label>{t('sliderReadMore')}</S.Label>
        <S.RectContainer>
          <svg
            width="300"
            height="60"
            viewBox="0 0 300 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="path"
              d="M1.5 1.5H298.5V58.5H1.5V1.5Z"
              stroke="#E9B000"
              strokeWidth="3"
              className="button-path-yellow"
            />
          </svg>
        </S.RectContainer>
      </LocalizedLink>
    </S.ButtonWrapper>
  );
};

export default ButtonCallAction;
