import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Curtains } from 'react-curtains';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../templates/Hero';
import Contact from '../templates/Contact';
import BlogRoll from '../templates/BlogRoll';
import HomeProjects from '../components/HomeProjects';
import HomeServices from '../components/HomeServices';
import HomeWebsiteFeatures from '../components/HomeWebsiteFeatures';
import { isBrowser } from '../utils/browser';
// Blog is turned of to the time tha will have some articles
const Index = ({ data }) => {
  let devicePixelRatio = 1;
  const { nodes: posts } = data.blog;
  const { nodes: projects } = data.projects;
  const {
    childMdx: { frontmatter: services },
  } = data.services;
  const {
    childMdx: { frontmatter: features },
  } = data.features;

  const postsWithBlogSlug = posts.map(({ childMdx: post }) => ({
    ...post,
    fields: {
      ...post.fields,
      slug: `/blog${post.fields.slug}`,
    },
  }));
  const projectsWithSlug = projects.map(({ childMdx: project }) => ({
    ...project,
    fields: {
      ...project.fields,
      slug: `/strony-internetowe${project.fields.slug}`,
    },
  }));

  useEffect(() => {
    if (isBrowser) {
      devicePixelRatio = window.devicePixelRatio;
    }
  }, []);

  return (
    <Curtains
      pixelRatio={Math.min(1.5, devicePixelRatio)}
      autoRender={false} // we'll use gsap ticker in App.js instead
    >
      <Layout>
        <Hero />
        <HomeWebsiteFeatures features={features} />
        <HomeProjects projects={projectsWithSlug} />
        <HomeServices services={services} />
        <BlogRoll posts={postsWithBlogSlug} />
        <Contact />
      </Layout>
    </Curtains>
  );
};

export default Index;

export const Head = () => <SEO title="Home - Giedeé Informatyka" />;

export const query = graphql`
  query ($locale: String!) {
    blog: allFile(
      filter: {
        sourceInstanceName: { eq: "blog" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
      limit: 3
    ) {
      nodes {
        childMdx {
          excerpt
          id
          slug
          fields {
            slug
          }
          frontmatter {
            title
            featuredimage {
              alt
              src {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    quality: 85
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            date(formatString: "DD MMMM YYYY", locale: $locale)
          }
        }
      }
    }
    projects: allFile(
      filter: {
        sourceInstanceName: { eq: "projects" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
      sort: { fields: childrenMdx___frontmatter___date, order: DESC }
      limit: 3
    ) {
      nodes {
        childMdx {
          id
          fields {
            slug
          }
          frontmatter {
            title
            heading
            subtitle
            featuredimage {
              alt
              src {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    quality: 85
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
    services: file(
      extension: { eq: "mdx" }
      relativeDirectory: { eq: "it-services" }
      childMdx: { fields: { locale: { eq: $locale } } }
    ) {
      childMdx {
        frontmatter {
          subtitle
          title
          items {
            title
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED
                  height: 60
                  quality: 85
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
    features: file(
      extension: { eq: "mdx" }
      relativeDirectory: { eq: "website-features" }
      childMdx: { fields: { locale: { eq: $locale } } }
    ) {
      childMdx {
        frontmatter {
          subtitle
          title
          exerpt
          items {
            title
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED
                  height: 60
                  quality: 85
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
