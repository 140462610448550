/* eslint-disable no-shadow */
import React, { useRef, useState, useEffect } from 'react';
import { Plane, useCurtains } from 'react-curtains';
import gsap from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import { useTranslation } from 'react-i18next';

import ButtonSliderNav from '../ButtonSliderNav';
import ButtonCallAction from '../ButtonCallAction';
import HeroTitle from '../HeroTitle';

import { vertexShader, fragmentShader } from './shaders';
import notebook from '../../assets/images/notebook.webp';
import rj from '../../assets/images/rj.webp';
import klawiatura from '../../assets/images/klawiatura.webp';
import pecet from '../../assets/images/pecet.webp';
import displacement from '../../assets/images/displacement.webp';

import * as S from './styled';
import './styles.css';

function HeroSlider() {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  const titles = [
    {
      name: `${t('sliderNameOne')}`,
      description: `${t('sliderDescOne')}`,
    },
    {
      name: `${t('sliderNameTwo')}`,
      description: `${t('sliderDescTwo')}`,
    },
    {
      name: `${t('sliderNameThree')}`,
      description: `${t('sliderDescThree')}`,
    },
    {
      name: `${t('sliderNameFour')}`,
      description: `${t('sliderDescFour')}`,
    },
    // {
    //   name: 'Grafika komputerowa',
    //   description: 'Przygotowanie projektów i obróbka graficzna',
    // },
  ];
  gsap.registerPlugin(TextPlugin);
  let initialSlide;
  const [plane, setPlane] = useState(null);
  const slideshowInner = useRef(null);
  // slideshow states
  const [activeTexture, setActiveTexture] = useState(1);
  const [maxTextures, setMaxTextures] = useState(0);

  const isChanging = useRef(false);
  const tween = useRef(null);

  const playAnimation = (axY = -1500, d = 0.5) => {
    gsap
      .timeline()
      .to('.hero-element', {
        clipPath: 'polygon(0 0, 100% 0, 100% 0, 0 0)',
        y: axY,
        delay: d,
        stagger: 0.1,
        duration: d,
        alpha: 0,
      })
      .to('.hero-button', {
        y: 0,
        alpha: 0,
      })
      .set('.hero-title', {
        text: { value: titles[activeTexture - 1].name },
      })
      .set(
        '.hero-description',
        {
          text: { value: titles[activeTexture - 1].description },
        },
        '<'
      )
      .to(
        '.hero-element',
        {
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
          y: 0,
          textShadow: '3px 2px 10px var(--gray-dark)',
          stagger: 0.2,
          duration: 0.3,
          delay: 0.2,
          alpha: 1,
        },
        '<'
      )
      .to('.hero-element', {
        clipPath: 'polygon(0 0, 100% 0, 100% 0, 0 0)',
        y: -200,
        delay: 1,
        stagger: 0.2,
        duration: 0.5,
      })
      .to('.hero-element', {
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
        stagger: 0.2,
        delay: 1,
        duration: 0.2,
      })
      .to(
        '.hero-button',
        {
          delay: 1,
          duration: 1.5,
          y: -200,
          alpha: 1,
        },
        '-=0.9'
      )
      .restart();
  };
  useEffect(() => {
    if (slideshowInner.current) {
      setMaxTextures(slideshowInner.current.childElementCount - 1);
    }
    const currentTween = tween.current;
    return () => {
      if (currentTween) {
        currentTween.kill();
      }
    };
  }, []);

  const activeTex = useRef(null);
  const nextTex = useRef(null);

  const uniforms = {
    transitionTimer: {
      name: 'uTransitionTimer',
      type: '1f',
      value: 0,
    },
  };

  const onLoading = (plane, texture) => {
    // improve texture rendering on small screens with LINEAR_MIPMAP_NEAREST minFilter
    texture.setMinFilter(texture.gl.LINEAR_MIPMAP_NEAREST);
  };

  const onReady = (plane) => {
    setPlane(plane);
  };

  useEffect(() => {
    initialSlide = setTimeout(() => {
      const autoPlay = document.querySelectorAll('.nextButton');
      if (autoPlay) {
        autoPlay[1].click();
      }
    }, 8000);
    return () => {
      clearTimeout(initialSlide);
    };
  });

  const onClick = (event) => {
    clearTimeout(initialSlide);
    const nextSlide = event.currentTarget.dataset.position || 'right';

    if (!isChanging.current && plane) {
      isChanging.current = true;

      // check what will be next image
      let nextTextureIndex;
      if (activeTexture < maxTextures && nextSlide === 'right') {
        nextTextureIndex = activeTexture + 1;
      } else if (activeTexture > 1 && nextSlide === 'left') {
        nextTextureIndex = activeTexture - 1;
      } else if (activeTexture === 1 && nextSlide === 'left') {
        nextTextureIndex = maxTextures;
      } else {
        nextTextureIndex = 1;
      }
      // apply it to our next texture
      nextTex.current.setSource(plane.images[nextTextureIndex]);

      tween.current = gsap
        .timeline()
        .call(playAnimation)
        .to(plane.uniforms.transitionTimer, {
          duration: 1.75,
          value: 90,
          ease: 'power2.inOut',
          onComplete: () => {
            isChanging.current = false;
            tween.current = null;

            plane.uniforms.transitionTimer.value = 0;

            const activeTextureIndex = nextTextureIndex;
            // our next texture becomes our active texture
            activeTex.current.setSource(
              plane.images[activeTextureIndex]
            );
            setActiveTexture(activeTextureIndex);
          },
        });
    }
  };

  useCurtains(() => {
    if (plane) {
      playAnimation(-500, 0);
      // first we set our very first image as the active texture
      activeTex.current = plane.createTexture({
        sampler: 'activeTex',
        fromTexture: plane.textures[activeTexture],
      });
      // next we set the second image as next texture but this is not mandatory
      // as we will reset the next texture on slide change
      nextTex.current = plane.createTexture({
        sampler: 'nextTex',
        fromTexture: plane.textures[activeTexture + 1],
      });
    }
  }, [plane]);

  return (
    <>
      <Plane
        className="Slideshow"
        // plane init parameters
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        uniforms={uniforms}
        // plane events
        onLoading={onLoading}
        onReady={onReady}
      >
        <div ref={slideshowInner}>
          <img
            src={displacement}
            data-sampler="displacement"
            alt="displacement"
            loading="lazy"
          />
          <img
            src={notebook}
            alt="notebook"
            className="slide-image"
            loading="lazy"
          />
          <img
            src={klawiatura}
            alt="klawiatura"
            className="slide-image"
            loading="lazy"
          />
          <img
            src={pecet}
            alt="pecet"
            className="slide-image"
            loading="lazy"
          />
          <img
            src={rj}
            alt="rj45"
            className="slide-image"
            loading="lazy"
          />
        </div>
      </Plane>
      <S.TitleContainer>
        <HeroTitle
          text={titles[activeTexture - 1].name}
          subtitle={titles[activeTexture - 1].description}
        />
        <ButtonCallAction />
      </S.TitleContainer>
      <ButtonSliderNav onClick={onClick} position="left" />
      <ButtonSliderNav
        onClick={onClick}
        position="right"
        className="nextButton"
      />
    </>
  );
}

export default HeroSlider;
