import React, { useEffect } from 'react';
import { useCurtains } from 'react-curtains';
import gsap from 'gsap';
import { Power1 } from 'gsap/all';
import * as S from './styled';
import ButtonScroll from '../../components/ButtonScroll';
import HeroSlider from '../../components/HeroSlider';
import { isDocument } from '../../utils/browser';

const Hero = () => {
  useCurtains((curtains) => {
    // use gsap ticker to render our curtains scene
    gsap.ticker.add(curtains.render.bind(curtains));
  });
  gsap.config({
    nullTargetWarn: false,
    trialWarn: false,
  });

  const tl = gsap.timeline({
    paused: true,
    defaults: {
      duration: 1.2,
      ease: Power1.easeOut,
    },
  });

  useEffect(() => {
    if (isDocument) {
      tl.to(
        '.nextButton',
        {
          delay: 1.5,
          opacity: 0.6,
          duration: 1,
          x: 0,
          stagger: 0.3,
        },
        '-=0.5'
      )
        .to('.header-element-scroll', {
          alpha: 1,
          duration: 0.8,
          delay: 0.5,
          y: 0,
          stagger: 0.3,
        })
        .play();
    }
  }, []);

  return (
    <S.Wrapper>
      <S.Container>
        <HeroSlider />
        <S.Radial />
      </S.Container>
      <S.LightBox />
      <S.ScrollContainer>
        <ButtonScroll />
      </S.ScrollContainer>
    </S.Wrapper>
  );
};

export default Hero;
