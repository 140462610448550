import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styled';

import TitlePage from '../../components/TitlePage';
import ContactForm from '../../components/ContactForm';
import ContactSocialMedia from '../../components/ContactSocialMedia';

const Contact = () => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });

  return (
    <S.Wrapper id="contact">
      <S.Container>
        <TitlePage
          text={t('contact')}
          subtitle={t('contactSubtitle')}
          contactSubtitle
        />
        <S.Row>
          <S.Block>
            <ContactForm />
          </S.Block>
          <S.Block>
            <ContactSocialMedia />
          </S.Block>
        </S.Row>
      </S.Container>
    </S.Wrapper>
  );
};

export default Contact;
